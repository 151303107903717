/* eslint-disable no-console */
// File: frontend/mint/src/utils/fetchNFTsForCollection.js

/**
 * @param {string} collectionAddress
 * @param {number} limit
 * @param {number} offset
 * @param {boolean} indirectOwnership
 * @returns {Promise<Object|null>}
 */
async function fetchNFTsForCollection(
  userAddress,
  collectionAddress,
  limit = 1000,
  offset = 0,
  indirectOwnership = false
) {
  const encodedCollectionAddress = encodeURIComponent(collectionAddress);
  const endpoint = `https://tonapi.io/v2/accounts/${userAddress}/nfts?collection=${encodedCollectionAddress}&limit=${limit}&offset=${offset}&indirect_ownership=${indirectOwnership}`;
  const apiKey = process.env.REACT_APP_TON_CONSOLE_API_KEY;

  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${apiKey}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const nfts = await response.json();
    console.log('NFTs for collection:', nfts);

    return nfts;
  } catch (error) {
    console.error('Error fetching NFTs for collection:', error);
    return null;
  }
}

export default fetchNFTsForCollection;
