// File: frontend/mint/src/index.js
import { Buffer } from 'buffer';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import App from './App';
import './index.css';

// Set up Buffer for the global scope
window.Buffer = window.Buffer || Buffer;

const manifestUrl = process.env.REACT_APP_TONCONNECT_MANIFEST_URL;

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <TonConnectUIProvider manifestUrl={manifestUrl}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </TonConnectUIProvider>
  </React.StrictMode>
);
