// File: frontend/src/constants/textConstants.js
export const APE_SOCIETY_TITLE = 'Ape Society';

export const PREMINT_PHRASE = 'Not all apes swing at the same height.';

export const NFT_MINTING_TIMELINE =
  'NFTs are minted immediately after purchase, but it may take a few minutes before they appear in your wallet.';

export const NFT_REVEAL_TIMELINE =
  'Reveal of NFTs will occur shortly after confirmation of the transaction.';

export const APE_SOCIETY_OVERVIEW =
  'In the heart of the jungle, every ape finds their treasure.';

export const APE_SOCIETY_OVERVIEW_LONG =
  "It's more than just owning art; it's about stepping into a new realm of gaming and blockchain synergy.";

export const UNIQUE_ANIMATED_NFTS =
  "Discover 33 unique, animated NFTs in our collection. These aren't just digital assets; they're masterpieces of movement, adding life to your collection.";

export const TELEGRAM_MINI_APP_GAME =
  "Jump into our Web game directly in Telegram! Experience our Mini App - the cutting-edge tech transforming Telegram into a gaming hub. Ready to farm tokens (or bananas, because we're all about that ape life) in a contextually rich game environment?";

export const BLACKMARKET_NFT_SERVICE =
  "Check out our BlackMarket for TON NFT trading. Initially a Telegram bot service, we're evolving with a sleek UI and enhanced security through smart contracts. Not just a marketplace; it's a leap towards secure, transparent NFT trading.";
