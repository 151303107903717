/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */
import React, { useEffect, useMemo } from 'react';
import { Decimal } from 'decimal.js';
import PropTypes from 'prop-types';
import Button from '../../UI/Buttons';
import { useTheme } from '../../../contexts/themeContext';
import { useSendMintTransaction } from '../../../hooks/useSendTransaction';
import styles from './style.module.css';
import {
  NFT_MINTING_TIMELINE,
  PREMINT_PHRASE,
} from '../../../constants/textConstants';

function MintModal({ isOpen, onClose, mintCount, setMintCount, maxMint }) {
  const { theme } = useTheme();
  const [sendMintTransaction, confirmationInProgress] =
    useSendMintTransaction();

  const themeClass = {
    dark: styles.darkTheme,
    light: styles.lightTheme,
  };

  const modalClass = `${styles.modalOverlay} ${
    isOpen ? styles.active : ''
  } ${themeClass[theme] || ''}`;

  const totalCost = useMemo(() => {
    return new Decimal(3).mul(mintCount).toFixed(0);
  }, [mintCount]);

  const handleMint = async () => {
    try {
      await sendMintTransaction(mintCount, totalCost);
      onClose();
    } catch (error) {
      console.error('Minting failed', error);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target.className.includes(styles.modalOverlay)) {
        onClose();
      }
    };

    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  }, [onClose]);

  if (!isOpen) return null;

  const updateMintCount = (increment) => {
    setMintCount((prevCount) => {
      const newCount = prevCount + increment;
      return Math.min(Math.max(1, newCount), maxMint);
    });
  };

  return (
    <div className={modalClass}>
      <div className={styles.modalContent}>
        <div className={styles.counterContainer}>
          <button
            type='button'
            className={styles.decreaseFiveButton}
            onClick={() => updateMintCount(-5)}
          >
            -5
          </button>
          <button
            type='button'
            className={styles.counterButton}
            onClick={() => updateMintCount(-1)}
          >
            -
          </button>
          <span className={styles.mintCount}>{mintCount}</span>
          <button
            type='button'
            className={styles.counterButton}
            onClick={() => updateMintCount(1)}
          >
            +
          </button>
          <button
            type='button'
            className={styles.increaseFiveButton}
            onClick={() => updateMintCount(5)}
          >
            +5
          </button>
        </div>
        <div className={styles.mintingTimeline}>{PREMINT_PHRASE}</div>
        <div
          className={styles.totalCost}
          style={{ color: theme === 'light' ? '#000' : '#fff' }}
        >
          <img
            src='/icons/ton/ton_symbol.svg'
            alt='TON'
            className={styles.tonSymbol}
          />
          <div className={styles.costText}>Total: {totalCost} TON</div>
        </div>
        <Button
          label={confirmationInProgress ? 'LFG!' : 'Mint'}
          onClick={handleMint}
          className={styles.confirmButton}
          disabled={confirmationInProgress}
        />
        <div className={styles.mintingTimeline}>{NFT_MINTING_TIMELINE}</div>
      </div>
    </div>
  );
}

MintModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mintCount: PropTypes.number.isRequired,
  setMintCount: PropTypes.func.isRequired,
  maxMint: PropTypes.number.isRequired,
};

export default MintModal;
