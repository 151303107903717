// File: frontend/mint/src/components/Mint/index.js
import React from 'react';
import MintSelector from '../MintSelector';

function Mint() {
  return (
    <div>
      <MintSelector />
    </div>
  );
}

export default Mint;
