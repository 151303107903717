/* eslint-disable no-console */
// File: frontend/mint/src/utils/fetchNextItemIndex.js

/**
 * @param {string} contractAddress
 * @returns {Promise<number|null>}
 */
async function getCollectionData(contractAddress) {
  const endpoint = `https://tonapi.io/v2/blockchain/accounts/${contractAddress}/methods/get_collection_data`;
  const apiKey = process.env.REACT_APP_TON_CONSOLE_API_KEY;
  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${apiKey}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    const nextItemIndex = data.decoded.next_item_index
      ? parseInt(data.decoded.next_item_index, 10)
      : null;

    return nextItemIndex;
  } catch (error) {
    console.error('Error fetching contract state:', error);
    return null;
  }
}

export default getCollectionData;
