// File: frontend/mint/src/components/Mint/index.js
import React from 'react';

function Connect() {
  return (
    <div>
      <h2> </h2>
    </div>
  );
}

export default Connect;
