// File: frontend/mint/src/components/Footer/index.js
import React from 'react';
import styles from './style.module.css';

function Footer() {
  return (
    <footer className={styles.appFooter}>
      <p>
        Powered by{' '}
        <a
          href='https://ton.org/'
          target='_blank'
          rel='noopener noreferrer'
          className={styles.tonLink}
        >
          TON
        </a>{' '}
        Blockchain
      </p>
    </footer>
  );
}

export default Footer;
