// File: frontend/mint/src/components/UI/Buttons/index.js
import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

function Button({ onClick, label, className, disabled }) {
  return (
    <button
      type='button'
      className={`${styles.button} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
}

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  className: '',
  disabled: false,
};

export default Button;
