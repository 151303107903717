import React from 'react';
import { useTonWallet } from '@tonconnect/ui-react';
import { useTheme } from '../../contexts/themeContext';

import useScrollRestoration from '../../hooks/useScrollRestoration';
import styles from './style.module.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Info from '../../components/Info';
import Mint from '../../components/Mint';
import MintBar from '../../components/MintBar';
import Connect from '../../components/Connect';

function HomePage() {
  useScrollRestoration();
  const { theme } = useTheme();
  const tonWallet = useTonWallet();
  const isWalletConnected = !!tonWallet;

  const pageStyle = theme
    ? `${styles.gamePage} ${styles.darkTheme}`
    : `${styles.gamePage} ${styles.lightTheme}`;

  return (
    <div className={pageStyle}>
      <Header />
      <div className={styles.mainContent}>
        <Info />
        <MintBar />
        {isWalletConnected ? <Mint /> : <Connect />}
      </div>
      <Footer />
    </div>
  );
}

export default React.memo(HomePage);
