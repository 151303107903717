// File: frontend/mint/src/utils/transformTonURL.js
const transformTonURL = (tonURL) => {
  const prefix = 'tonstorage://';
  const gatewayPrefix = 'https://tonbyte.com/gateway/';

  if (tonURL && tonURL.startsWith(prefix)) {
    return `${gatewayPrefix}${tonURL.slice(prefix.length)}`;
  }
  return tonURL;
};

export default transformTonURL;
