// File: frontend/mint/src/components/NFTGrid/MediaElement/index.js
import React from 'react';
import PropTypes from 'prop-types';
import LoadingView from '../../LoadingView/index';
import styles from './style.module.css';
import { LOGO_PATH } from '../../../constants/imagePaths';

function MediaElementComponent({ contentURL, nftName, className }) {
  if (!contentURL) return <LoadingView src={LOGO_PATH} />;

  const imageClassName = className
    ? `${styles.nftImage} ${className}`
    : styles.nftImage;

  const isVideo = contentURL.endsWith('.mp4');
  if (isVideo) {
    return (
      <video
        src={contentURL}
        alt={nftName}
        className={imageClassName}
        autoPlay
        loop
        muted
        controls={false}
        preload='auto'
        playsInline
      />
    );
  }
  return <img src={contentURL} alt={nftName} className={imageClassName} />;
}

MediaElementComponent.propTypes = {
  contentURL: PropTypes.string,
  nftName: PropTypes.string.isRequired,
  className: PropTypes.string,
};

MediaElementComponent.defaultProps = {
  contentURL: '',
  className: '',
};

const MediaElement = React.memo(MediaElementComponent);
MediaElement.displayName = 'MediaElement';

export default MediaElement;
