/* eslint-disable prettier/prettier */
/* eslint-disable camelcase */
/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
// File: frontend/mint/src/hooks/useSendTransaction.js
import { useState } from 'react';
import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
// import { Address } from 'ton';
import { toNano } from 'ton';
import generateMintPayload from '../utils/generateMintPayload';
import generateBatchMintPayload from '../utils/generateBatchMintPayload';
import generateRevealPayload from '../utils/generateRevealPayload';

import getCollectionData from '../utils/fetchNextItemIndex';

export function useSendMintTransaction() {
  const [tonConnectUI] = useTonConnectUI();
  const [confirmationInProgress, setConfirmationInProgress] = useState(false);
  const userAddress = useTonAddress();
  const collectionAddress = process.env.REACT_APP_COLLECTION_ADDRESS;
  const minterRawAddress = process.env.REACT_APP_MINTER_RAW_ADDRESS;

  async function sendMintTransaction(numberOfNfts, totalCost) {
    setConfirmationInProgress(true);

    try {
      const collectionId = 0;
      const amountInNanoTON = (totalCost * 1000000000).toString();

      const nextItemIndex = await getCollectionData(collectionAddress);
      if (nextItemIndex === null) {
        console.error("Couldn't get next item index.");
        setConfirmationInProgress(false);
        return false;
      }

      let payload;
      const items = [];
      for (let i = 0; i < numberOfNfts; i += 1) {
        items.push({
          passAmount: toNano('0.05'),
          index: nextItemIndex + i,
          ownerAddress: userAddress,
          editorAddress: userAddress,
          content: (nextItemIndex + i).toString(),
        });
      }
      if (items.length === 1) {
        payload = generateMintPayload(userAddress, collectionId, nextItemIndex);
      } else {
        payload = generateBatchMintPayload(collectionId, items);
      }

      const mintTransaction = {
        messages: [
          {
            address: minterRawAddress,
            amount: amountInNanoTON,
            payload,
          },
        ],
        validUntil: Math.round(Date.now() / 1000) + 360,
      };

      console.log('Sending mint transaction:', mintTransaction);
      await tonConnectUI.sendTransaction(mintTransaction);
      return true;
    } catch (error) {
      console.error('Error sending mint transaction:', error);
      return false;
    } finally {
      setConfirmationInProgress(false);
    }
  }

  return [sendMintTransaction, confirmationInProgress];
}

export function useSendRevealTransaction() {
  const [tonConnectUI] = useTonConnectUI();
  const [confirmationInProgress, setConfirmationInProgress] = useState(false);

  async function sendRevealTransaction(nft_address) {
    setConfirmationInProgress(true);

    const revealTransaction = {
      validUntil: Math.round(Date.now() / 1000) + 360,
      messages: [
        {
          address: nft_address,
          amount: '1000000000',
          payload: generateRevealPayload(),
        },
      ],
    };

    try {
      await tonConnectUI.sendTransaction(revealTransaction);
      console.log(
        'Reveal transaction [reveal] sent successfully ->',
        revealTransaction
      );
      return true;
    } catch (error) {
      console.error('Error sending reveal transaction:', error);
      return false;
    } finally {
      setConfirmationInProgress(false);
    }
  }

  return [sendRevealTransaction, confirmationInProgress];
}
