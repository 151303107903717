/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
// File: frontend/mint/src/utils/generateBatchMintPayload.js
import { Base64 } from '@tonconnect/protocol';
import { Address, beginCell, Dictionary } from 'ton';
import { crc32 } from './crc32.ts';

const MintDictValue = {
  serialize(src, builder) {
    const nftItemMessage = beginCell();

    const itemContent = beginCell();
    itemContent.storeBuffer(Buffer.from(src.content));

    const ownerAddress = Address.parse(src.ownerAddress);
    const editorAddress = Address.parse(src.editorAddress);

    nftItemMessage.storeAddress(ownerAddress);
    nftItemMessage.storeAddress(editorAddress);
    nftItemMessage.storeRef(itemContent);

    builder.storeCoins(src.passAmount);
    builder.storeRef(nftItemMessage);
  },
  parse() {
    return {
      passAmount: 0n,
      index: 0,
      ownerAddress: new Address(0, Buffer.alloc(32)),
      content: '',
      editorAddress: new Address(0, Buffer.alloc(32)),
    };
  },
};

export default function generateBatchMintPayload(collectionId, items) {
  if (!items || !Array.isArray(items)) {
    throw new Error('Invalid or missing items array');
  }

  if (items.length > 250) {
    throw new Error('Too long list');
  }

  console.info('items -> ', items);
  const op = crc32('op::batch_nft_mint');
  const dict = Dictionary.empty(Dictionary.Keys.Uint(64), MintDictValue);
  for (const item of items) {
    dict.set(item.index, item);
  }

  console.log('Batch Mint Message Cell Prepared');

  const messageBodyCell = beginCell()
    .storeUint(op, 32)
    .storeUint(0, 64)
    .storeUint(collectionId, 64)
    .storeDict(dict)
    .endCell();

  console.log('Message Body Cell Prepared');

  const encodedPayload = Base64.encode(messageBodyCell.toBoc());
  console.log('Encoded Payload:', encodedPayload);

  return encodedPayload;
}
