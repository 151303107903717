/* eslint-disable consistent-return */
/* eslint-disable no-console */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSendRevealTransaction } from '../../../hooks/useSendTransaction';
import Button from '../../UI/Buttons';
import MediaElement from '../../NFTGrid/MediaElement';
import transformTonURL from '../../../utils/transformTonURL';
import styles from './style.module.css';
import { NFT_REVEAL_TIMELINE } from '../../../constants/textConstants';

function NFTDetailsModal({ nft, isOpen, onClose }) {
  const [sendRevealTransaction, confirmationInProgress] =
    useSendRevealTransaction();

  useEffect(() => {
    if (!isOpen) return;

    const handleOutsideClick = (event) => {
      if (event.target.className.includes(styles.modalOverlay)) {
        onClose();
      }
    };

    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen, onClose]);

  const contentType = nft.metadata.content_type;
  const imageURL = transformTonURL(nft.metadata.image);
  const contentURL = transformTonURL(nft.metadata.content_url);
  const displayURL = contentType === 'video/mp4' ? contentURL : imageURL;
  const nftName = nft.metadata?.name || 'On the way :)';
  const nftDescription = nft.metadata?.description || '';
  const TONVIEWER_URL_PREFIX = 'https://tonviewer.com/';
  const isHiddenContent =
    nft.metadata.image && nft.metadata.image.endsWith('hidden_content_nft.png');
  const tonviewerUrl = `${TONVIEWER_URL_PREFIX}${nft.address}?section=nft`;

  const openTonviewer = () => {
    window.open(tonviewerUrl, '_blank');
  };

  console.info('isHiddenContent: ', isHiddenContent);

  const handleRevealClick = async () => {
    const nftAddress = nft.address;
    await sendRevealTransaction(nftAddress);
  };

  return (
    <div className={`${styles.modalOverlay} ${isOpen ? styles.active : ''}`}>
      <div className={styles.modalContent}>
        <div className={styles.contentGroup}>
          <MediaElement
            contentURL={displayURL}
            nftName={nftName}
            className={styles.modalNftImage}
          />
          <div className={styles.nftDetails}>
            <h3 className={styles.nftName}>{nftName}</h3>
            <p className={styles.nftDescription}>{nftDescription}</p>
          </div>
        </div>
        <div className={styles.bottomGroup}>
          {!isHiddenContent ? (
            <Button
              label='Tonviewer'
              onClick={openTonviewer}
              className={styles.confirmButton}
              disabled={false}
            />
          ) : (
            <Button
              label={confirmationInProgress ? 'Processing...' : 'Reveal'}
              onClick={handleRevealClick}
              className={styles.confirmButton}
              disabled={confirmationInProgress}
            />
          )}
        </div>
        {isHiddenContent && (
          <div className={styles.revealTimeline}>{NFT_REVEAL_TIMELINE}</div>
        )}
      </div>
    </div>
  );
}

NFTDetailsModal.propTypes = {
  nft: PropTypes.shape({
    index: PropTypes.number.isRequired,
    address: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      content_url: PropTypes.string,
      image: PropTypes.string,
      content_type: PropTypes.string,
    }).isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NFTDetailsModal;
