// File: frontend/mint/src/utils/generateRevealPayload.js
import { Base64 } from '@tonconnect/protocol';
import { beginCell } from 'ton';

export default function generateRevealPayload() {
  const op = 0x5fcc3d1a; // reveal NFT opcode

  const queryId = Date.now();

  // eslint-disable-next-line no-console
  const messageBody = beginCell()
    .storeUint(op, 32)
    .storeUint(queryId, 64)
    .endCell();

  return Base64.encode(messageBody.toBoc());
}
