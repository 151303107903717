/* eslint-disable no-console */
import React, { useState, useEffect, useMemo } from 'react';
import { useTonWallet } from '@tonconnect/ui-react';
import Header from '../../components/Header';
// import Footer from '../../components/Footer';
import NFTGrid from '../../components/NFTGrid';
import fetchNFTsForCollection from '../../utils/fetchNFTsForCollection';
import useTonAddress from '../../hooks/useTonAddress';
// import styles from './style.module.css';

// Define animationNames outside the component to avoid redefinition on each render
const animationNames = [
  'shadow',
  'apestain',
  'banana',
  'basket',
  'cook',
  'cupidon',
  'death',
  'fire',
  'ghost',
  'harry',
  'header',
  'hell',
  'hulk',
  'ice',
  'iron',
  'jason',
  'joker',
  'mario',
  'matrix',
  'mia',
  'mona',
  'moto',
  'pharaon',
  'pikachu',
  'poseidon',
  'rasta',
  'robot',
  'scream',
  'skully',
  'soldier',
  'subzero',
  'toxic',
  'trader',
  'ufo',
  'zombie',
];

function UserPage() {
  const [nfts, setNfts] = useState(null);
  const tonWallet = useTonWallet();
  const { rawAddress } = useTonAddress(false);
  const isWalletConnected = !!tonWallet;

  const imagesPath = process.env.REACT_APP_IMAGES_PATH || '/images/apes';
  const animationsPath =
    process.env.REACT_APP_ANIMATIONS_PATH || '/webp_assets/unique';
  const pngCount = 10; // Adjust this number based on your images

  const allMedia = useMemo(() => {
    const items = [];
    let imageCounter = 0;
    let animationCounter = 0;
    while (items.length < pngCount + animationNames.length) {
      if (imageCounter < pngCount) {
        items.push({
          id: `png${imageCounter + 1}`,
          media: `${imagesPath}/image${imageCounter + 1}.png`,
          type: 'image',
        });
        imageCounter += 1;
      }
      if (animationCounter < animationNames.length) {
        items.push({
          id: animationNames[animationCounter],
          media: `${animationsPath}/${animationNames[animationCounter]}.webp`,
          type: 'animation',
        });
        animationCounter += 1;
      }
    }
    return items;
  }, [imagesPath, animationsPath, pngCount]);

  const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
  useEffect(() => {
    const interval =
      allMedia[currentMediaIndex].type === 'animation' ? 3000 : 1000;
    const timeoutId = setTimeout(() => {
      setCurrentMediaIndex((prevIndex) => (prevIndex + 1) % allMedia.length);
    }, interval);
    return () => clearTimeout(timeoutId);
  }, [currentMediaIndex, allMedia]);

  useEffect(() => {
    async function loadNFTs() {
      if (isWalletConnected && rawAddress) {
        const collectionAddress = process.env.REACT_APP_COLLECTION_ADDRESS;
        try {
          const fetchedNFTs = await fetchNFTsForCollection(
            rawAddress,
            collectionAddress
          );
          setNfts(fetchedNFTs.nft_items);
        } catch (error) {
          console.error('Failed to fetch NFTs:', error);
        }
      }
    }
    loadNFTs();
  }, [isWalletConnected, rawAddress]);

  return (
    <div className='App'>
      <Header />
      <div className='mainContent'>
        {isWalletConnected ? (
          <>
            <NFTGrid nfts={{ nftItems: nfts }} />
            {/* <Footer /> */}
          </>
        ) : null}
        {/* <div className='centeredContent'>
          <img
            src={allMedia[currentMediaIndex].media}
            alt={`Media ${allMedia[currentMediaIndex].id}`}
            className={styles.nftMedia}
          />
        </div> */}
      </div>
    </div>
  );
}

export default React.memo(UserPage);
