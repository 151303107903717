// File: frontend/mint/src/components/LoadingView/index.js
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '../../contexts/themeContext';
import styles from './style.module.css';

function LoadingView({ src }) {
  const { theme } = useTheme();
  const isGif = src && src.endsWith('.gif');

  const containerStyle = {
    backgroundColor:
      theme === 'light' ? 'var(--light-bg-color)' : 'var(--black-bg-color)',
  };

  return (
    <div style={containerStyle} className={styles.loadingContainer}>
      {isGif ? (
        <img src={src} alt='Loading' className={styles.nftImage} />
      ) : (
        <img src={src} alt='Loading' className={styles.nftImage} />
      )}
    </div>
  );
}

LoadingView.propTypes = {
  src: PropTypes.string.isRequired,
};

export default LoadingView;
