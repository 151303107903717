import React from 'react';
import PropTypes from 'prop-types';
import { TonConnectButton } from '@tonconnect/ui-react';
import { useTheme } from '../../contexts/themeContext';
import { useConnection } from '../../contexts/ConnectionContext';
import styles from './style.module.css';

function Header() {
  const { theme } = useTheme();
  const { handleConnect, handleDisconnect } = useConnection();
  const twitterIcon = theme === 'light' ? '/icons/x-dark.svg' : '/icons/x.svg';

  return (
    <header className={styles.header}>
      <nav className={styles.socialIcons}>
        <SocialIcon
          href='https://x.com/ape_society_ton'
          src={twitterIcon}
          alt='Twitter'
        />
        <SocialIcon
          href='https://t.me/ape_society'
          src='/icons/telegram.svg'
          alt='Telegram'
        />
      </nav>
      <TonConnectButtonWrapper
        handleConnect={handleConnect}
        handleDisconnect={handleDisconnect}
      />
    </header>
  );
}

function SocialIcon({ href, src, alt }) {
  return (
    <a href={href} target='_blank' rel='noopener noreferrer'>
      <img src={src} alt={alt} />
    </a>
  );
}

SocialIcon.propTypes = {
  href: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

function TonConnectButtonWrapper({ handleConnect, handleDisconnect }) {
  return (
    <div className={styles.tonDisconnectButtonContainer}>
      <TonConnectButton
        className={styles.tonDisconnectButton}
        onConnect={handleConnect}
        onDisconnect={handleDisconnect}
      />
    </div>
  );
}

TonConnectButtonWrapper.propTypes = {
  handleConnect: PropTypes.func.isRequired,
  handleDisconnect: PropTypes.func.isRequired,
};

export default Header;
