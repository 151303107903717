import React, { useState, useEffect, useMemo } from 'react';
import styles from './style.module.css';
import { APE_SOCIETY_OVERVIEW } from '../../constants/textConstants';

const animationNames = [
  'shadow',
  'apestain',
  'banana',
  'basket',
  'cook',
  'cupidon',
  'death',
  'fire',
  'ghost',
  'harry',
  'header',
  'hell',
  'hulk',
  'ice',
  'iron',
  'jason',
  'joker',
  'mario',
  'matrix',
  'mia',
  'mona',
  'moto',
  'pharaon',
  'pikachu',
  'poseidon',
  'rasta',
  'robot',
  'scream',
  'skully',
  'soldier',
  'subzero',
  'toxic',
  'trader',
  'ufo',
  'zombie',
];

function InfoBlock() {
  const imagesPath = process.env.REACT_APP_IMAGES_PATH || '/images/apes';
  const animationsPath =
    process.env.REACT_APP_ANIMATIONS_PATH || '/webp_assets/unique';
  const pngCount = 22;

  const nfts = useMemo(() => {
    const items = [];
    let imageCounter = 0;
    let animationCounter = 0;
    while (items.length < pngCount + animationNames.length) {
      for (let i = 0; i < 3 && imageCounter < pngCount; i += 1) {
        items.push({
          id: `png${imageCounter + 1}`,
          media: `${imagesPath}/ape${imageCounter + 1}.png`,
          type: 'image',
        });
        imageCounter += 1;
      }
      if (animationCounter < animationNames.length) {
        items.push({
          id: animationNames[animationCounter],
          media: `${animationsPath}/${animationNames[animationCounter]}.webp`,
          type: 'animation',
        });
        animationCounter += 1;
      }
    }
    return items;
  }, [imagesPath, animationsPath, pngCount]);

  const [currentNftIndex, setCurrentNftIndex] = useState(0);
  useEffect(() => {
    const interval = nfts[currentNftIndex].type === 'animation' ? 3000 : 1000;
    const timeoutId = setTimeout(() => {
      setCurrentNftIndex((prevIndex) => (prevIndex + 1) % nfts.length);
    }, interval);
    return () => clearTimeout(timeoutId);
  }, [currentNftIndex, nfts]);

  const renderMedia = (nft) => (
    <div className={styles.nftMediaContainer}>
      <img src={nft.media} alt={`NFT ${nft.id}`} className={styles.nftMedia} />
    </div>
  );

  return (
    <div id='info-block' className={styles.infoBlockContainer}>
      <div className={styles.titleText}>{APE_SOCIETY_OVERVIEW}</div>
      <div className={styles.nftCardBlock}>
        {renderMedia(nfts[currentNftIndex])}
      </div>
    </div>
  );
}

export default InfoBlock;
