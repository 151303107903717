// File: frontend/mint/src/hooks/useTonAddress.js
import { useTonAddress } from '@tonconnect/ui-react';

// Updated to default export
export default function Address() {
  const userFriendlyAddress = useTonAddress();
  const rawAddress = useTonAddress(false);

  return { userFriendlyAddress, rawAddress };
}
