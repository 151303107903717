/* eslint-disable no-console */
// File: src/utils/fetchCollectionDetails.js

/**
 * Fetches NFT collection details from the API.
 * @returns {Promise<Object|null>}
 */
async function fetchCollectionDetails() {
  const collectionAddress = process.env.REACT_APP_COLLECTION_RAW_ADDRESS;
  const encodedCollectionAddress = encodeURIComponent(collectionAddress);
  const endpoint = `https://tonapi.io/v2/nfts/collections/${encodedCollectionAddress}`;

  try {
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const collectionDetails = await response.json();

    return collectionDetails;
  } catch (error) {
    console.error('Error fetching collection details:', error);
    return null;
  }
}

export default fetchCollectionDetails;
