// File: frontend/mint/src/utils/generateMintPayload.js
import { Base64 } from '@tonconnect/protocol';
import { Address, beginCell } from 'ton';
import { crc32 } from './crc32.ts';

export default function generateMintPayload(
  userAddress,
  collectionId,
  itemIndex
) {
  const op = crc32('op::mint_nft_item');

  const nftContent = beginCell();
  nftContent.storeBuffer(Buffer.from('hidden_nft.json'));

  const nftItemMessageCell = beginCell();

  nftItemMessageCell.storeAddress(Address.parse(userAddress));
  nftItemMessageCell.storeAddress(Address.parse(userAddress));
  nftItemMessageCell.storeRef(nftContent);

  const messageBodyCell = beginCell()
    .storeUint(op, 32)
    .storeUint(0, 64)
    .storeUint(collectionId, 64)
    .storeUint(itemIndex, 64)
    .storeRef(nftItemMessageCell)
    .endCell();

  const encodedPayload = Base64.encode(messageBodyCell.toBoc());

  return encodedPayload;
}
