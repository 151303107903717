/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import Button from '../UI/Buttons';
import MintModal from '../Modals/MintModal';
import getCollectionData from '../../utils/fetchNextItemIndex';
import styles from './style.module.css';

function MintSelector() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mintCount, setMintCount] = useState(1);
  const [maxMint, setMaxMint] = useState(0);
  // const [maxMintReached, setMaxMintReached] = useState(false);
  const collectionAddress = process.env.REACT_APP_COLLECTION_ADDRESS;

  useEffect(() => {
    getCollectionData(collectionAddress)
      .then((index) => {
        if (index !== null && index < 1000) {
          setMaxMint(1000 - index); // Calculate how many more can be minted
        } else {
          setMaxMint(0);
        }
      })
      .catch((error) => {
        console.error('Failed to fetch collection data:', error);
      });
  }, [collectionAddress]);

  return (
    <div className={styles.mintSelectorContainer}>
      <div className={styles.buttonContainer}>
        <Button
          label={maxMint === 0 ? 'Getgems Marketplace' : 'Mint NFT'}
          onClick={() => {
            if (maxMint === 0) {
              window.open(
                'https://getgems.io/collection/EQBlZIhngm9ATuFbYhKdS70fvEP2-_IWU4JiS5NkEuykhdJu',
                '_blank'
              );
            } else {
              setIsModalOpen(true);
            }
          }}
          className={styles.mintButton}
        />
      </div>
      <MintModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        mintCount={mintCount}
        setMintCount={setMintCount}
        maxMint={maxMint}
      />
    </div>
  );
}

export default MintSelector;
