// File: frontend/mint/src/components/MintBar/index.js
import React, { useState, useEffect } from 'react';
import fetchCollectionDetails from '../../utils/fetchCollectionDetails';
import styles from './style.module.css';

function MintBar() {
  const [mintedCount, setMintedCount] = useState(0);
  const totalSupply = parseInt(process.env.REACT_APP_TOTAL_SUPPLY, 10);
  const publicSaleSupply = parseInt(
    process.env.REACT_APP_PUBLIC_SALE_SUPPLY,
    10
  );

  useEffect(() => {
    async function updateMintedCount() {
      const collectionDetails = await fetchCollectionDetails();
      if (
        collectionDetails &&
        collectionDetails.next_item_index !== undefined
      ) {
        setMintedCount(collectionDetails.next_item_index);
      }
    }

    updateMintedCount();
  }, []);

  const publicSalePercentage = (mintedCount / publicSaleSupply) * 100;

  return (
    <div className={styles.mintBarOuterContainer}>
      <div className={styles.mintBarContainer}>
        <div
          className={styles.publicSaleBar}
          style={{ width: `${publicSalePercentage}%` }}
          title='Public Sale Info'
        />
        <div className={styles.mintInfo}>
          {mintedCount} / {totalSupply}
        </div>
      </div>
    </div>
  );
}

export default MintBar;
