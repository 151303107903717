import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// import Button from '../UI/Buttons';
import transformTonURL from '../../utils/transformTonURL';
import MediaElement from './MediaElement';
import NFTDetailsModal from '../Modals/NFTDetailsModal';
import styles from './style.module.css';

function SimpleButton({ label, onClick }) {
  return (
    <button type='button' onClick={onClick} className={styles.simpleButton}>
      {label}
    </button>
  );
}

SimpleButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

function NFTGrid({ nfts }) {
  const [selectedNFT, setSelectedNFT] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleNFTClick = (nft) => {
    setSelectedNFT(nft);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const nftItems = nfts?.nftItems ?? [];

  if (nftItems.length === 0) {
    return (
      <div className={styles.centeredContainer}>
        <div className={styles.emptyState}>You don&apos;t own an ape :(</div>
        <SimpleButton label='Mint' onClick={() => navigate('/')} />
      </div>
    );
  }

  return (
    <div className={styles.grid}>
      {nftItems.map((nft, index) => {
        const nftName = nft.metadata?.name || 'On the way :)';
        const imageURL = transformTonURL(nft.metadata.image);
        const contentURL = transformTonURL(nft.metadata.content_url);
        const contentType = nft.metadata.content_type;

        const displayURL = contentType === 'video/mp4' ? contentURL : imageURL;

        return (
          <div
            key={nft.id || `nft-${index}`}
            className={styles.nftCard}
            onClick={() => handleNFTClick(nft)}
            onKeyDown={(e) =>
              (e.key === 'Enter' || e.key === ' ') && handleNFTClick(nft)
            }
            role='button'
            tabIndex='0'
          >
            <MediaElement contentURL={displayURL} nftName={nftName} />
            <div className={styles.nftInfo}>
              <h3 className={styles.nftName}>{nftName}</h3>
            </div>
          </div>
        );
      })}
      {selectedNFT && (
        <NFTDetailsModal
          nft={selectedNFT}
          isOpen={isModalOpen}
          onClose={closeModal}
        />
      )}
    </div>
  );
}

NFTGrid.propTypes = {
  nfts: PropTypes.shape({
    nftItems: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        metadata: PropTypes.shape({
          name: PropTypes.string,
          content_url: PropTypes.string,
          image: PropTypes.string,
          content_type: PropTypes.string,
        }),
        description: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default React.memo(NFTGrid);
