/* eslint-disable no-console */
// frontend/mint/src/contexts/ConnectionContext.js
import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

const ConnectionContext = createContext();

export function useConnection() {
  return useContext(ConnectionContext);
}

export function ConnectionProvider({ children }) {
  const [isConnected, setIsConnected] = useState(false);

  const handleConnect = useCallback(() => {
    console.log('Connected');
    setIsConnected(true);
  }, []);

  const handleDisconnect = useCallback(() => {
    console.log('Disconnected');
    setIsConnected(false);
  }, []);

  const value = useMemo(
    () => ({
      isConnected,
      handleConnect,
      handleDisconnect,
    }),
    [isConnected, handleConnect, handleDisconnect]
  );

  return (
    <ConnectionContext.Provider value={value}>
      {children}
    </ConnectionContext.Provider>
  );
}

ConnectionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
