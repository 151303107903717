// frontend/mint/src/App.js
import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ConnectionProvider } from './contexts/ConnectionContext';
import { ThemeProvider } from './contexts/themeContext'; // Import ThemeProvider
import HomePage from './pages/main';
import UserPage from './pages/profile';
import './index.css';

function App() {
  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();

      if (!window.Telegram.WebApp.isExpanded) {
        window.Telegram.WebApp.expand();
      }
    }
  }, []);

  return (
    <ThemeProvider>
      <ConnectionProvider>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/user-profile' element={<UserPage />} />
        </Routes>
      </ConnectionProvider>
    </ThemeProvider>
  );
}

export default App;
